import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
    component: () => import('@/landing/Layout.vue') ,
    children : [
      { path: '/login', name: 'Login', component: () => import('@/landing/Login.vue') },
      { path: '/mfasetup', name: 'MFASetup', component: () => import('@/landing/MFASetup.vue') },
      { path: '/reset', name: 'ResetPassword', component: () => import('@/landing/ResetPassword.vue') }
    ]
  },
  {
    path: '/participant',
    redirect: '/participant/sessions',
    component: () => import('@/participant/Layout.vue') ,
    children : [
      { path: 'sessions', name: 'Sessions', component: () => import('@/participant/SessionCalendar.vue') },
      { path: 'session', name: 'SessionDetail', component: () => import('@/participant/Session.vue') },
      { path: 'patient', name: 'Patient', component: () => import('@/participant/Patient.vue') },
      { path: 'logevents', name: 'LogEvents', component: () => import('@/participant/LogEvents.vue') },
      { path: 'surveys', name: 'PatientSurveys', component: () => import('@/participant/SurveyList.vue') },
      { path: 'summary', name: 'SleepSummary', component: () => import('@/participant/SleepSummary.vue') }
    ]
  },
  {
    path: '/user',
    redirect: '/user/search',
    component: () => import('@/user/Layout.vue') ,
    children : [
      { path: 'searchsleep', name: 'SearchSleep', component: () => import('@/user/SearchSleep.vue') },
      { path: 'searchpatient', name: 'SearchPatient', component: () => import('@/user/SearchPatient.vue') },
      { path: 'addparticipant', name: 'AddParticipant', component: () => import('@/user/AddParticipant.vue') },
      { path: 'participantbatch', name: 'ParticipantBatch', component: () => import('@/user/ParticipantBatch.vue') }
    ]
  },
  {
    path: '/admin',
    redirect: '/admin/sessions',
    component: () => import('@/admin/Layout.vue') ,
    children : [
      { path: 'sessions', name: 'UserSessions', component: () => import('@/admin/Sessions.vue') },
      { path: 'users', name: 'Users', component: () => import('@/admin/Users.vue') },
      { path: 'user', name: 'User', component: () => import('@/admin/User.vue') },
      { path: 'groups', name: 'UserGroups', component: () => import('@/admin/UserGroups.vue') },
      { path: 'group', name: 'UserGroup', component: () => import('@/admin/UserGroup.vue') },
      { path: 'batch', name: 'Batch', component: () => import('@/admin/BatchManager.vue') },
      { path: 'logs', name: 'Logs', component: () => import('@/admin/LogManager.vue') },
      { path: 'dashboard', name: 'AdminDashboard', component: () => import('@/admin/Dashboard.vue') },
      { path: 'surveys', name: 'SurveyList', component: () => import('@/admin/SurveyList.vue') },
      { path: 'survey', name: 'Survey', component: () => import('@/admin/Survey.vue') },
      { path: 'surveysummary', name: 'SurveySummary', component: () => import('@/admin/SurveySummary.vue') }

    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
