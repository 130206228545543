import axios from 'axios'

var NetClient = {
  ctx: { token: ''},
  sessionID: -1,
  resp: [],
  retval: 'OK',
  resetPid: -1,
  resetToken: '',
  recents: [],
  init: function(_router) {
    this.router = _router


    this.ctx = JSON.parse(window.localStorage.getItem('ctx'))
    if(null == this.ctx) this.ctx = { token: '', uid: '', patienID: -1, uname: 'Not Logged In'}


    //
    // parse any get variables
    //
    var query = window.location.search.substring(1)
    var params = []
    var param = []
    var list
    var ii

    list = query.split('&')
    for(ii=0;ii<list.length;ii++)
    {
      console.log(list[ii])
      param = list[ii].split('=')
      if(param.length > 1) params[param[0]] = param[1]
    }

    if(typeof params['pid'] != 'undefined') this.resetPid = params['pid']
    if(typeof params['token'] != 'undefined') this.resetToken = params['token']

  },
  logOut () {
      console.log("logout called")
      this.doRequest('rest/session',{action: 'logout'}).then(msg => {
        this.router.push({ name: 'Login'})
      })
  },
  doRequest: function (_svc, _req) {
    var nc = this

    this.resp = []
    this.retval ='OK'

    _req.nw_token = this.ctx.token
    return new Promise(function (resolve, reject) {
      axios({
        method: 'post',
        baseURL: process.env.VUE_APP_HOST_NAME,
        url: _svc,
        headers: { 'nw-uid': nc.ctx.uid },
        data: _req
      }).then(axresp => {
        var msg = axresp.data

        if(typeof msg == 'undefined')
        {
          reject(new Error('Invalid response from server. No data element found'))
          return
        }

        if(typeof msg.resp != 'undefined') NetClient.resp = msg.resp
        if(typeof msg.retval != 'undefined') NetClient.retval = msg.retval

        if ('AUTH' === msg.retval) {
          nc.router.push({ name: 'Login'})
          resolve(msg)
        }

        if(typeof msg.data != 'undefined')
        {
          if(typeof msg.data.ctx !== 'undefined')
          {
            NetClient.ctx = msg.data.ctx
            window.localStorage.setItem('ctx', JSON.stringify(NetClient.ctx))
          }

          if(typeof msg.data.recents != 'undefined') NetClient.recents = msg.data.recents
        }
        resolve(msg)
      }).catch(err => {
        console.log('error in response!')
        reject(err)
      })
    })
  }
}

export default NetClient
