import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import('@/assets/nightware.css')
import NetClient from '@/util/netclient.js'

Vue.config.productionTip = false

NetClient.init(router)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
